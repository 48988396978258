import {FC, PropsWithChildren} from 'react';
import ProductCardSkeleton from '@hy-vee/web-ecommerce/lib/components/product-card-skeleton';

import {IOrderProduct} from 'autogen/IOrderProduct';

import ProductCardCart from '../product-card/product-card-cart';

import Swimlane, {ISwimlaneLazyLoadProps} from './swimlane';

type IProduct = IOrderProduct & {
    slotTypeId?: number;
    responseProductId?: string | null;
    responseId?: string | null;
    isSponsored?: boolean | null;
};

export type IProductSwimlaneProps = Omit<ISwimlaneLazyLoadProps, 'childComponentRenderer' | 'LoadingComponent'> & {
    aisleId?: string;
    showListIcon?: boolean;
    swimlaneType?: string;
    childComponentRenderer?: (data: IProduct, index?: number) => JSX.Element;
};

const ProductSwimlane: FC<PropsWithChildren<PropsWithChildren<IProductSwimlaneProps>>> = ({
    childComponentRenderer,
    ...props
}) => {
    const renderProductCard = (product: IProduct, index?: number) => {
        return (
            <ProductCardCart
                aisleId={props.aisleId}
                index={index}
                isSponsored={product.isSponsored}
                productId={product.productId}
                responseId={product.responseId}
                responseProductId={product.responseProductId}
                showListIcon={props.showListIcon !== undefined ? props.showListIcon : true}
                showUnavailableProducts={false}
                slotTypeId={product.slotTypeId}
                swimlaneType={props.swimlaneType}
            />
        );
    };

    return (
        <Swimlane
            {...props}
            LoadingComponent={ProductCardSkeleton}
            childComponentRenderer={childComponentRenderer ?? renderProductCard}
            swimlaneDrawerStyles={{
                border: 'none'
            }}
        />
    );
};

export default ProductSwimlane;
