import {FC, PropsWithChildren, useEffect} from 'react';
import {GlobalNavigationHeader, updateGlobalNavigationAOCartQuantities} from '@hy-vee/global-navigation';

import {SearchCategories} from 'client/enums/search-filters';
import {ENHANCED_SEARCH, SEASONS_SEARCH_IDEAS, WEB_ORDER_MIN_FEE} from 'client/enums/feature-toggle-names';
import {useReservationDrawer} from 'client/hooks/use-reservation-drawer';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {IStore} from 'autogen/IStore';

import {
    handleGlobalNavigationDataChange,
    useGlobalNavigationHeaderCart,
    useGlobalNavigationStoreConfig
} from '../../utils/global-navigation-helpers';

interface IGlobalNavigationHeaderWrapperProps {
    hideCartFlyout?: Boolean;
    store: IStore;
}

export const GlobalNavigationHeaderWrapper: FC<
    PropsWithChildren<PropsWithChildren<IGlobalNavigationHeaderWrapperProps>>
> = ({hideCartFlyout, store}) => {
    const {featureEnabled} = useFeatureToggle();
    const {setIsOpen: setIsReservationDrawerOpen} = useReservationDrawer();
    const {data: cart, error: cartError, loading: cartLoading} = useGlobalNavigationHeaderCart();
    const storeConfig = useGlobalNavigationStoreConfig(store, cart, setIsReservationDrawerOpen);

    const isEcommIdeasEnhancedSearchWeb = featureEnabled(SEASONS_SEARCH_IDEAS);
    const isSeasonsSearchFeatureEnabled = featureEnabled(ENHANCED_SEARCH);
    const isWebOrderMinFeeEnabled = featureEnabled(WEB_ORDER_MIN_FEE);

    useEffect(() => {
        if (cart && !cartError && !cartLoading) {
            updateGlobalNavigationAOCartQuantities(cart);
        }
    }, [cartError, cartLoading, cart]);

    return (
        <GlobalNavigationHeader
            hideCartFlyout={hideCartFlyout}
            initialSearchCategory={SearchCategories.PRODUCTS}
            isEcommIdeasEnhancedSearchWeb={isEcommIdeasEnhancedSearchWeb}
            isSeasonsSearchFeatureEnabled={isSeasonsSearchFeatureEnabled}
            isWebOrderMinFeeEnabled={isWebOrderMinFeeEnabled}
            onDataChange={handleGlobalNavigationDataChange}
            storeConfig={storeConfig}
        />
    );
};
